import gsap, { Power2, Power4 } from 'gsap';
import * as THREE from 'three';
import { Vector3, PlaneGeometry } from 'three';
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
// import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
// import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
// import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import { GetBy } from '../_app/cuchillo/core/Element';

import { Basics, isDebug, isMobile } from '../_app/cuchillo/core/Basics';
import { Interaction } from '../_app/cuchillo/core/Interaction';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Project from './Project';
import { TimelineScroll } from '../scroll/TimelineScroll';
import Path, { PATH_DUMMIES } from './Path';
import Thumbnails from '../components/Thumbnails';
import Counter from '../components/Counter';
import Titles from '../components/Titles';
import Header from '../layout/Header';
import States from './States';
import CanvasTexture from './CanvasTexture';
// import { debug } from './debug';

// import { DotScreenShader } from '../shaders/postprocessing';

export const DURATION = 2;
export const CAMERA_SETTINGS = {
  initial: new THREE.Vector3(-6800, 3800, 300),
  desktop: new THREE.Vector3(-1000, 500, 300),
  mobile: new THREE.Vector3(-700, 0, 300),
  lookAt: new THREE.Vector3(0, 0, -800),
  detail: new THREE.Vector3(0, 0, 300),
  lookAtDetail: new THREE.Vector3(0, 0, 0),
};

export const MOUSE_SETTINGS = {
  mouseIntensity: 50,
  mouseDamping: 0.8,
  maxRotation: new THREE.Vector2(0.04, 0.04),
  movFactor: 30
};
export const SCROLL_ANIMATIONS = {
  maxRotation: new THREE.Vector3(0.4, 0, 0.05),
  separation: 0.075,
  progressCorrection: 0.92,
  showTitle: 0.7,
  hideTitle: 0.25,
};
export const HOVER_ANIMATIONS = {
  color: '#00A4B2',
  colorIntensity: 0.4
};
export const POSTPROCESSING_SETTINGS = {
  noiseIntensity: 0.8,
  scanlineIntensity: 0.065,
  scanlineCount: 500,
  grayscale: false
};

export const CANVAS_TEXTURE = new CanvasTexture();
export const GEOMETRY = new PlaneGeometry(1, 1, 16, 16);

const TMP = new Vector3();

/**
 * TO-DO:
 *  - BUGS:
 *       - Click del project + scroll
 * */
export default class Timeline {
  static container;
  static scene;
  static renderer;
  static camera;
  static items = [];
  static state;
  static raycaster;
  static mouse = new THREE.Vector2();
  static isEnabled = false;
  static thumbnails;
  // static composer;
  static lookAt = new THREE.Vector3();
  static lookAtTarget = new THREE.Vector3();
  static position = new THREE.Vector3();
  static animated = false;
  controls;
  path;
  width = 0;
  height = 0;
  postpoEffect;

  static init(state = States.scroll) {
    this.width = window.innerWidth;
    this.height = window.innerHeight;
    this.aspectRatio = this.width / this.height;

    Timeline.state = state;
    Timeline.container = GetBy.id('projects-canvas');

    if (Timeline.state === States.detail) Timeline.animated = true;

    Timeline.scene = new THREE.Scene();
    Timeline.renderer = new THREE.WebGLRenderer({
      canvas: Timeline.container,
      alpha: false,
    });
    Timeline.renderer.sortObjects = false;
    Timeline.renderer.setClearColor(0x000000, 1);
    Timeline.renderer.setSize(this.width, this.height);
    Timeline.renderer.setPixelRatio(window.devicePixelRatio);

    Timeline.initCamera();

    // this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    // this.controls.enabled = false;

    this.path = new Path(Timeline.scene).path;

    Timeline.raycaster = new THREE.Raycaster();
    Timeline.thumbnails = new Thumbnails();

    // Initailizes scroll
    TimelineScroll.init(TimelineScroll.AXIS_Y, {
      smooth: true,
      multiplicator: isMobile ? 4 : 1,
      container: GetBy.id('projects-items')
    });

    // Setting up project counter total
    Counter.init(GetBy.selector('#projects-items .__project').length);
    Titles.init();

    // Postprocessing
    // Timeline.composer = new EffectComposer(Timeline.renderer);
    // Timeline.composer.addPass(new RenderPass(Timeline.scene, Timeline.camera));
    // Timeline.composer.setSize(this.width, this.height);

    // this.postpoEffect = new ShaderPass(DotScreenShader);
    // this.postpoEffect.renderToScreen = true;
    // Timeline.composer.addPass(this.postpoEffect);

    // Debug
    if (isDebug) {
      const axesHelper = new THREE.AxesHelper(1000);
      Timeline.scene.add(axesHelper);
      Timeline.scene.add(PATH_DUMMIES);

      // debug();
    }
  }

  static initCamera() {
    const aspect = this.width / this.height;
    const near = 0.1;
    const far = 15000;

    if (Timeline.state === States.scroll) {
      TMP.copy(CAMERA_SETTINGS.initial);
      Timeline.position.copy(CAMERA_SETTINGS.initial);
      Timeline.lookAt.copy(CAMERA_SETTINGS.lookAt);
      Timeline.lookAtTarget.copy(CAMERA_SETTINGS.lookAt);
    } else {
      Timeline.position.copy(CAMERA_SETTINGS.detail);
      Timeline.lookAt.copy(CAMERA_SETTINGS.lookAtDetail);
      Timeline.lookAtTarget.copy(CAMERA_SETTINGS.lookAtDetail);
    }

    Timeline.camera = new THREE.PerspectiveCamera(60, aspect, near, far);
    Timeline.camera.position.copy(Timeline.position);
    Timeline.camera.lookAt(Timeline.lookAt);
    Timeline.camera.fov = 2 * Math.atan(this.width / Timeline.camera.aspect / (2 * Timeline.camera.position.z)) * (180 / Math.PI);
    Timeline.camera.updateProjectionMatrix();
  }

  static registerProject(scrollItem) {
    const item = scrollItem.item;
    const id = scrollItem.id;
    const { type } = item.dataset;

    if (type !== 'image') return;

    const project = new Project(item, {
      id,
      radius: Timeline.radius,
      scrollItem: scrollItem,
      path: this.path
    });

    Timeline.items[id] = project;
    Timeline.scene.add(project.instance);
  }

  static start() {
    Timeline.isEnabled = true;
    TimelineScroll.start();
    window.addEventListener(Basics.clickEvent, Timeline.raycast);
  }

  static stop() {
    Timeline.isEnabled = false;
    TimelineScroll.stop();
    window.removeEventListener(Basics.clickEvent, Timeline.raycast);
  }

  static animate() {
    if (!Timeline.animated) {
      const camera = CAMERA_SETTINGS[isMobile ? 'mobile' : 'desktop'];
      gsap.to(TMP, {
        x: camera.x,
        y: camera.y,
        z: camera.z,
        duration: 1,
        ease: Power4.easeOut,
        onComplete: () => {
          Timeline.animated = true
          TimelineScroll.start();
          Timeline.thumbnails.show();
          Counter.show();
          Header.show();
        }
      });

      Object.keys(Timeline.items).sort((a, b) => a - b).map((key, i) => {
        const item = Timeline.items[key];
        gsap.delayedCall(.5 + .04 * i, () => {
          item.forceActive = true;
        });
        i++;
      });
    }
  }

  static activateProjects() {
    for (var key in Timeline.items) {
      const item = Timeline.items[key];
      item.forceActive = true;
    }
  }

  static hoverProject(id, hover = true) {
    Object.keys(Timeline.items).map((key) => {
      const item = Timeline.items[key];

      if (key === id) item.forceHover = hover;
    });
  }

  static activeProject(id, hover = true) {
    Object.keys(Timeline.items).map((key) => {
      const item = Timeline.items[key];

      if (key === id) item.activeProject = hover;
    });
  }

  static loop() {
    if (
      Timeline.renderer === undefined ||
      Timeline.scene === undefined ||
      Timeline.camera === undefined ||
      !Timeline.isEnabled
    )
      return;

    // this.postpoEffect.uniforms.amount.value += 0.01;

    // Hover effect
    Timeline.toggleMouse();

    TimelineScroll.loop();
    Timeline.thumbnails.loop();

    for (var key in Timeline.items) {
      const item = Timeline.items[key];
      const progress = item.progress;
      item.t = progress * SCROLL_ANIMATIONS.separation + SCROLL_ANIMATIONS.progressCorrection;
      // item.opacity = Timeline.state === States.scroll ? 1 : 0;
      item.loop();
    }

    if (Timeline.animated && Timeline.state === States.scroll) {
      // if (!this.controls.enabled) {
      const camera = CAMERA_SETTINGS[isMobile ? 'mobile' : 'desktop'];
      Timeline.position.copy(camera);
      Timeline.lookAt.copy(CAMERA_SETTINGS.lookAt);

      TMP.set(
        Timeline.position.x - this.mouse.x * MOUSE_SETTINGS.movFactor,
        Timeline.position.y - this.mouse.y * MOUSE_SETTINGS.movFactor * this.aspectRatio,
        Timeline.position.z
      );
      // }

      Timeline.camera.position.lerp(TMP, .019);
      Timeline.lookAtTarget.lerp(Timeline.lookAt, .019);
      Timeline.camera.lookAt(Timeline.lookAtTarget);
    }

    Timeline.renderer.render(Timeline.scene, Timeline.camera);
    // Timeline.composer.render(Timeline.scene, Timeline.camera);
  }

  static toggleMouse() {
    // Reset styles
    let cursor = 'default';

    if (Timeline.state !== States.scroll || isMobile) return;

    for (var key in Timeline.items) {
      const item = Timeline.items[key];
      item.hover = false;

      Timeline.thumbnails.clearHover();
    }

    // Gets new values
    this.mouse = {
      x: (Interaction.positions.mouse.x / this.width) * 2 - 1,
      y: -(Interaction.positions.mouse.y / this.height) * 2 + 1
    };
    Timeline.raycaster.setFromCamera(this.mouse, Timeline.camera);
    const intersects = Timeline.raycaster.intersectObjects(
      Timeline.scene.children
    );

    // Checks intersects
    for (var i = 0; i < intersects.length; i++) {
      const target = intersects[i].object.element;

      if (target !== undefined && target.visible) {
        Timeline.thumbnails.hoverThumbnail(target.element.dataset.thumbnail);

        cursor = 'pointer';
        target.hover = true;

        break;
      }
    }

    document.body.style.cursor = cursor;
  }

  static raycast(event) {
    if (Timeline.state === States.detail) return;

    const mouse = {
      x: (event.clientX / Metrics.WIDTH) * 2 - 1,
      y: -(event.clientY / Metrics.HEIGHT) * 2 + 1
    };

    Timeline.raycaster.setFromCamera(mouse, Timeline.camera);
    const intersects = Timeline.raycaster.intersectObjects(Timeline.scene.children);

    for (var i = 0; i < intersects.length; i++) {
      const target = intersects[i].object.element;

      // Comprobar opacity para no tener "falsos"
      if (target !== undefined && target.type === 'image' && target.active) {
        Timeline.pause(target.id);
        ControllerPage.changePage(target.link, 'push');
        break;
      }
    }
  }

  static pause(id) {
    TimelineScroll.stop();

    if (id !== undefined) {
      Timeline.state = States.detail;

      TimelineScroll.gotoAnchor(id);
      const item = Timeline.items[id];
      item.toggleState(States.detail);

      // Timeline.thumbnails.hide();
      // Counter.hide();
      // Titles.resetTitles();

      gsap.to(Timeline.camera.position, {
        x: CAMERA_SETTINGS.detail.x,
        y: CAMERA_SETTINGS.detail.y,
        z: CAMERA_SETTINGS.detail.z,
        duration: 2,
        ease: Power2.easeOut,
      });
      gsap.to(Timeline.lookAtTarget, {
        x: CAMERA_SETTINGS.lookAtDetail.x,
        y: CAMERA_SETTINGS.lookAtDetail.y,
        z: CAMERA_SETTINGS.lookAtDetail.z,
        duration: 2,
        ease: Power2.easeOut,
        onUpdate: () => {
          Timeline.camera.lookAt(Timeline.lookAtTarget);
        },
        onComplete: () => {
          Timeline.camera.lookAt(Timeline.lookAtTarget);
        }
      });
    }
  }

  static play(id) {
    if (id !== undefined) TimelineScroll.gotoAnchor(id);

    const camera = CAMERA_SETTINGS[isMobile ? 'mobile' : 'desktop'];
    gsap.to(Timeline.camera.position, {
      x: camera.x,
      y: camera.y,
      z: camera.z,
      duration: 1.5,
      ease: Power2.easeOut
    });
    gsap.to(Timeline.lookAtTarget, {
      x: CAMERA_SETTINGS.lookAt.x,
      y: CAMERA_SETTINGS.lookAt.y,
      z: CAMERA_SETTINGS.lookAt.z,
      duration: 1,
      ease: Power4.easeOut,
      onUpdate: () => {
        Timeline.camera.lookAt(Timeline.lookAtTarget);
      },
      onComplete: () => {
        Timeline.camera.lookAt(Timeline.lookAtTarget);

        if (id !== undefined) {
          const item = Timeline.items[id];
          item.toggleState(States.scroll);
        }
      }
    });
  }

  static resize() {
    this.width = window.innerWidth;
    this.height = window.innerHeight;
    this.aspectRatio = this.width / this.height;

    if (Timeline.thumbnails) Timeline.thumbnails.resize();

    if (Timeline.renderer) {
      Timeline.renderer.setSize(this.width, this.height);
      Timeline.camera.aspect = this.aspectRatio;
      // Timeline.camera.fov = 2 * Math.atan(this.width / Timeline.camera.aspect / (2 * Timeline.camera.position.z)) * (180 / Math.PI); // in degrees
      Timeline.camera.updateProjectionMatrix();

      Object.keys(Timeline.items).map((key) => {
        Timeline.items[key].resize();
      });
    }
  }
}
